<template>
  <div class="home">
    <b-container class="py-0">
      <b-card>
        <h1>
          Kedves
          <span v-if="invitedName">{{ invitedName }}</span>
          <span v-else>kis barátom</span>!
        </h1>
        <b-card-text>
          Szeretnél részese lenni a nyár legállatibb szülinapi bulijának? <br />Akkor tarts velem és augusztus 6-án ünnepeljük meg együtt a <span style="white-space: nowrap;">10. szülinapomat!</span> <br /> 
        </b-card-text>

        <div class="description">
          <b-button
            class="m-1"
            variant="link"
            size="lg"
            v-if="!description"
            @click="description = !description"
            >Részletek</b-button
          >
          <Transition name="description">
            <section
              class="description__body"
              :class="{ 'big-padding': description }"
              v-show="
                description &&
                (this.invitedName || this.invitedName != 'Csillagh Hanna')
              "
            >
              <h4>Helyszín</h4>
              <p>
                <a
                  href="https://g.page/PintoRanchPartiPajta?share"
                  target="_blank"
                  variant="link"
                  >Pinto Ranch<br />
                  (2141. Csömör, 108. utca 31)</a
                >
              </p>
              <h4>Időpont</h4>
              <p>2022.08.06. 15:00 - 18:00</p>
            </section>
          </Transition>
        </div>

        <b-card-text>
          Annak érdekében, hogy a bulin mindannyian jól érezzük magunkat, kérlek töltsd ki az alábbi űrlapot július 24-ig. Remélem, hogy találkozunk <br /> - Hanna -
        </b-card-text>
        <b-button
          class="custom-button"
          v-if="!apply"
          @click="apply = !apply"
          variant="outline-primary"
        >
          Mutasd az űrlapot!
        </b-button>
        <div class="if-apply" v-if="apply">
          <RegForm :invitedName="this.invitedName" />
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import RegForm from "@/components/RegForm.vue";

export default {
  name: "StarterView",
  data() {
    return {
      invitedName: this.$route.query.name,
      apply: false,
      description: false,
    };
  },
  mounted() {
    //
    if (!this.invitedName || this.invitedName == "" || this.invitedName == "Mészáros Dóra") {
      document.body.classList.add("top-secret");
    }
  },
  components: {
    RegForm,
  },
};
</script>


<style lang="scss" scoped>
$headline-font: 'Vollkorn SC', serif;
.container {
  display: flex;
  justify-content: center;
}
.card {
  border-radius: 0;
  justify-self: center;
  min-height: 100vh;
  max-width: 720px;
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background: url(@/assets/images/form-bg2.jpg);
  background-position: center top;
  .card-body {
    padding: 1rem 2rem;
    @media (min-width: 576px) {
      padding: 2rem 4rem;
    }
    .card-text {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
  .description {
    margin: 2rem 0;
    min-height: 150px;
    background: url(@/assets/images/flower-circle.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    .btn-link {
      font-family: $headline-font;
      background: radial-gradient(
          ellipse farthest-corner at right bottom,
          #fedb37 0%,
          #fdb931 8%,
          #9f7928 30%,
          #8a6e2f 40%,
          transparent 80%
        ),
        radial-gradient(
          ellipse farthest-corner at left top,
          #ffffff 0%,
          #ffffac 8%,
          #d1b464 25%,
          #5d4a1f 62.5%,
          #5d4a1f 100%
        );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      color: rgb(235, 186, 145);
      width: 150px;
      height: 150px;
      font-size: 1.2rem;
      text-decoration: none;
      text-indent: 10px;
    }
    .description__body {
      padding: 0rem 0;
      &.big-padding {
        padding: 6rem 0;
      }
      .v-enter-active,
      .v-leave-active {
        transition: opacity 0.5s ease;
      }

      .v-enter-from,
      .v-leave-to {
        opacity: 0;
      }
      a {
        color: inherit;
      }
      h4 {
        // padding-bottom: 0.5rem;
        // padding-top: 0.5rem;
        font-family: $headline-font;
        text-transform: capitalize;
        // background: #ca9d7f; /* fallback for old browsers */
        // background: -webkit-linear-gradient(
        //   to bottom,
        //   #fed7c0,
        //   #ca9d7f
        // ); /* Chrome 10-25, Safari 5.1-6 */
        // background: linear-gradient(
        //   to bottom,
        //   #fed7c0,
        //   #ca9d7f
        // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #b28930 30%,
        #8d702d 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #c99f3e 62.5%,
        #b28d36 100%
      );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    z-index: 10;
    position: relative;
    background: url(@/assets/images/flower01.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 10%;
    transform: rotate(180deg);
    @media (min-width: 576px) {
      background-size: 75%;
    }
    @media (min-width: 768px) {
      background-size: 50%;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 130px;
    z-index: 10;
    position: relative;
    background: url(@/assets/images/flower01.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 0%;
    @media (min-width: 576px) {
      height: 200px;
    }
    @media (min-width: 768px) {
      height: 250px;
    }
  }
  h1 {
    padding-bottom: 2rem;
    font-family: $headline-font;
    text-transform: capitalize;
    background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .custom-button {
    border: none;
    color: rgb(235, 186, 145);
    font-weight: bold;
    background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  // h1{
  //   font-size: 100px;
  //   font-family: 'DM Serif Display', serif;
  //   margin-bottom: 100px;
  //   &::before{
  //     content: '';
  //     display: block;
  //     position: absolute;
  //     width: 250px;
  //     height: 250px;
  //     background: url(@/assets/images/flower-circle.png);
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     left: calc(50% - 125px);
  //     top: -30px;
  //   }
  // }
}
.top-secret{
    .description__body{
      a{
        background: #444;
      }
    }
  }
</style>