<template>
  <div class="form">
    <b-form @submit.prevent="onSubmit" v-if="show">
      <b-form-radio-group
        v-model="form.selectedOption"
        :options="form.options"
        class="radios mb-3"
        value-field="item"
        text-field="name"
        size="lg"
      ></b-form-radio-group>

      <b-form-group label="Neved" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Neved"
          required
          size="lg"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="E-mail címed"
        label-for="input-email"
        description="Nem adom ki másnak, becsszó!"
      >
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          placeholder="Kapcsolattartó e-mail címe"
          required
          size="lg"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Allergiás vagy valamire?"
        label-for="input-allergies"
      >
        <b-form-input
          id="input-allergies"
          v-model="form.foodAllergies"
          type="text"
          placeholder="Ételérzékenység, ha van..."
          size="lg"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group
        label="Hány kísérővel érkezel?"
        label-for="input-parents"
        description="A létszámkorlát miatt fontos tudnom (max. 2)"
      >
        <b-form-spinbutton
          id="input-parents"
          v-model="form.parents"
          min="0"
          max="2"
          size="lg"
        ></b-form-spinbutton>
      </b-form-group> -->

      <b-form-group label="Egyéb kérés, üzenet" label-for="input-parents">
        <b-form-textarea
          id="input-txtMessage"
          v-model="form.txtMessage"
          rows="3"
          max-rows="6"
          placeholder="Ide jöhetnek az üzik, ötletek, kérések"
          size="lg"
        ></b-form-textarea>
      </b-form-group>
      <b-button type="submit" variant="primary" size="lg" class="custom-button"
        >Elküldöm!</b-button
      >
      <p v-if="sentForm" class="success">Sikeres küldés</p>
    </b-form>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  name: "RegForm",
  props: {
    invitedName: String,
  },
  data() {
    return {
      applications: [],
      applicationsData: [],
      sentForm: false,
      form: {
        name: this.invitedName,
        email: "",
        foodAllergies: "",
        parents: 0,
        txtMessage: "",
        selectedOption: "",
        options: [
          { item: "Ott leszek végig", name: "Ott leszek végig" },
          { item: "Beugrom egy kicsit", name: "Beugrom egy kicsit" },
          { item: "Nem tudok menni", name: "Nem tudok menni :(" },
        ],
      },
      show: true,
    };
  },
  // created() {
  //   db.collection("applications").onSnapshot((snapshotChange) => {
  //     this.applicationsData = [];
  //     snapshotChange.forEach((doc) => {
  //       this.applicationsData.push({
  //         key: doc.id,
  //         name: doc.data().name,
  //         email: doc.data().email
  //       });
  //     });
  //   });
  // },
  mounted() {},
  methods: {
    createInvitation(form) {
      db.collection("applications")
        .add({
          name: form.name,
          email: form.email,
          foodAllergies: form.foodAllergies,
          parents: form.parents,
          txtMessage: form.txtMessage,
          option: form.selectedOption
        })
        .then(() => {
          this.sentForm = true;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    // listApplications() {
    //   this.applicationsData = [];
    //   db.collection("applications")
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         this.applicationsData.push({
    //           id: doc.id,
    //           name: doc.data().name,
    //           email: doc.data().email,
    //           foodAllergies: doc.data().foodAllergies,
    //           parents: doc.data().parents,
    //         });
    //         console.log(doc.id, " => ", doc.data());
    //       });
    //     })
    //     .catch((error) => {
    //       console.log("Error getting documents: ", error);
    //     });
    // },
    onSubmit(event) {
      event.preventDefault();
      this.createInvitation(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin-top: 3rem;
  padding: 3rem 0rem 1rem 0rem;
  border-top: 1px solid goldenrod;
  @media (min-width: 768px) {
    border: 1px solid goldenrod;
    padding: 2rem 2rem;
  }
  @media (min-width: 768px) {
    border: 1px solid goldenrod;
    padding: 2rem 5rem;
  }
  #input-name {
    text-transform: capitalize;
  }
  .form-control {
    border: none;
  }
  .radios::v-deep {
    text-align: left;
    .custom-control {
      display: block;
      .custom-control-label {
        margin-bottom: 1rem;
        &::before {
          border: 1px solid darkgoldenrod;
          background: transparent;
        }
        &::after {
          border-radius: 50%;
          // background: darkgoldenrod;
        }
      }
      .custom-control-input:checked ~ .custom-control-label::after {
        background: darkgoldenrod;
      }
    }
  }
  .custom-button {
    font-family: "Vollkorn SC", serif;
    border: none;
    // border: 2px solid rgba($color: #9f7928, $alpha: .5);
    color: rgb(235, 186, 145);
    font-weight: bold;
    background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity 150ms linear;
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>