import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC-9dyHH5qCqPC43uGBtpS4xPWbKdc3k2Y",
    authDomain: "h10bd-f0064.firebaseapp.com",
    projectId: "h10bd-f0064",
    storageBucket: "h10bd-f0064.appspot.com",
    messagingSenderId: "790330211558",
    appId: "1:790330211558:web:0a1785b8184db4e7c30f27"
};

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  export const db = firebaseApp.firestore();