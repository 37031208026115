import Vue from 'vue'
import VueRouter from 'vue-router'
import StarterView from '../views/StarterView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Hanna 10',
    component: StarterView
  },
  {
    path: '/resztvevok',
    name: 'Résztvevők',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ParticipantView.vue')
  }
]

const router = new VueRouter({
  // mode: createWebHistory(),
  base: '/',
  routes
})

export default router
